@import "../../node_modules/bootstrap";
@import "../../node_modules/@fortawesome/fontawesome-free/css/all.css";
@import "../../node_modules/@fontsource/libre-barcode-128/index.css";

body {
    padding-bottom:100px;
    overflow-y: scroll;
}

div.content {
    padding-top: 5px;
}

div.alert_place {
    height:50px;
    padding:0px;
    margin:5px;
    margin-left:auto;
    margin-right:auto;
}

div.align-right {
    float:right;
    display:inline;
}

th {
    text-align:center;
}

table {
    text-align:center;
}

div.card-body {
    margin:0px;
    padding:0px;
}

div.card-body table {
    margin-bottom:0px;
}

div.dash {
    text-align:center;
}

div.row .dash {
    margin-bottom:20px;
    margin-top:12px;
}

.barcode128-mini {
  font-family: "Libre Barcode 128";
  font-size:30px;
}

.barcode128-maxi {
  font-family: "Libre Barcode 128";
  font-size:100px;
}

span.duplicate {
    /*color:#ffc107;*/
    color:#dc3545;
    font-weight:bold;
}

span.temporary {
    /*color:#ffc107;*/
    color:#ffc107;
    font-weight:bold;
}

span.stock {
    font-size:16px;
    padding-left:10px;
    padding-right:10px;
}

div.weather {
    margin-bottom:35px;
}

.generated
{
    color:#000000;
    font-size:14px;
    font-style:italic;
}

span.navbar-text a:link{
	text-decoration: none;
}

span.navbar-text a:visited{
	text-decoration: none;
}

span.navbar-text a:hover{
	text-decoration: none;
}

span.navbar-text a:active{
	text-decoration: none;
}


